import { useGetAllUsers } from "../../http-hooks/useUser";
import { useGetAllUnitMasters } from "../../http-hooks/useUnitMaster";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { dashboardCards } from "../../utils/navigation";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardPage() {
  const userType = localStorage.getItem("storeform-user-type");
  const navigate = useNavigate();

  
  const { data: allUsersData, refetch : refetchAllUsers } = useGetAllUsers({}, false);
  const { data: allUnitMasters } = useGetAllUnitMasters();

  const cards = dashboardCards.map((project) => {
    let card = project;
    if (card.slug === "users") {
      card.label = `${allUsersData?.length} Users`;
    } else if (card.slug === "unit_masters") {
      card.label = `${allUnitMasters?.length} Units`;
    }
    return card;
  }).filter(card => card.access.includes(userType));

  useEffect(() => {
    const user_card = dashboardCards.find(p => p.slug === "users")
    if (user_card.access.includes(userType)) {
      refetchAllUsers();
    }
  })
  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div>
        {/* <h2 className="text-sm font-medium text-gray-500">Pinned Projects</h2> */}
        
          <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
            {cards.map((project) => (
              <li
                key={project.name}
                className="cursor-pointer col-span-1 flex rounded-md shadow-sm"
                onClick={() => navigate(`${project.href}`)}
              >
                <div
                  className={classNames(
                    project.bgColor,
                    "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                  )}
                >
                  {project.initials}
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <span>{project.name}</span>
                    <p className="text-gray-500">{project.label}</p>
                  </div>
                  {/* <div className="flex-shrink-0 pr-2">
                    <button
                      type="button"
                      className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        aria-hidden="true"
                        className="h-5 w-5"
                      />
                    </button>
                  </div> */}
                </div>
              </li>
            ))}
          </ul>
        
      </div>
    </main>
  );
}
